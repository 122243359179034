import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import gsap from 'gsap';
import media from '../styles/media';
import Cookies from 'universal-cookie';
import Colors from '../styles/Colors';
import twitter from '../assets/img/i_twitter_white.svg';
import discord from '../assets/img/i_discord_white.svg';
import hamburger from '../assets/img/hamburger.svg';
import { useEthers, Web3Ethers } from '@usedapp/core';
import { PARTY_ANIMALZ_ETH_ACCOUNT } from '../lib/utils/consts';
import { useStore } from '../lib/state/state';

const Header = () => {
  const wrapperRef: any = useRef(null);
  const cookies: any = new Cookies();
  const user: any = useStore((state: any) => state.user);
  const updateUser: any = useStore((state: any) => state.updateUser);
  const updateLibrary: any = useStore((state: any) => state.updateLibrary);

  const { library, activateBrowserWallet, deactivate, account }: Web3Ethers = useEthers();
  const [menuOpen, setMenuOpen]: [menuOpen: boolean, setMenuOpen: Function] = useState(false);
  const [connected, setConnected]: [connected: boolean, setConnected: Function] = useState(false);
  const [ethAddress, setEthAddress]: [ethAddress: string, setEthAddress: Function] = useState('');
  const [userObj, setUserObj]: any = useState(user);

  const handleConnectWallet = async (e: any) => {
    e.preventDefault();
    !account ? await activateBrowserWallet() : await deactivate();
    await setUserObj({ ...userObj, address: account, connected: connected });
    await updateUser({ ...userObj });
  };

  const handleAccount = async () => {
    account ? await setConnected(true) : await setConnected(false);
    setEthAddress(account);
    cookies.set(PARTY_ANIMALZ_ETH_ACCOUNT, account, {
      path: '/',
      maxAge: 60 * 60 * 24 * 365,
    });
    await setUserObj({
      ...userObj,
      address: account,
      connected: connected,
      PARTY_ANIMALZ_ETH_ACCOUNT: account,
    });
    await updateUser({ ...userObj });
  };

  useEffect(() => {
    gsap.to(wrapperRef.current, {
      opacity: 1,
      delay: 0.1,
      duration: 0.5,
    });
    handleAccount();
  }, []);

  useEffect(() => {
    if (library) {
      setConnected(true);
      setEthAddress(account);
      updateLibrary(library);
      setUserObj({ ...userObj, address: account, connected: connected });
      updateUser({ ...userObj });
    }
    handleAccount();
  }, [account]);

  return (
    <Wrapper ref={wrapperRef}>
      <WrapInner>
        <HeaderLeft>
          <a href="https://twitter.com/passnft" target="_blank">
            <StyledImgA src={twitter} />
          </a>
          <a href="https://discord.gg/3kAMYZJvyP" target="_blank">
            <StyledImgA src={discord} />
          </a>
        </HeaderLeft>
      </WrapInner>

      <WrapRight>
        <StyledLink to="/underconstruction">
          <TopNavButton>Partnerships</TopNavButton>
        </StyledLink>
        <StyledA onClick={(e) => handleConnectWallet(e)}>
          <TopNavButton>
            {connected ? `Disconnect Wallet ${ethAddress.substring(0, 5)}` : 'Connect Wallet'}
          </TopNavButton>
        </StyledA>
        <MenuButton onClick={() => setMenuOpen(!menuOpen)} />
        <MobileMenu open={menuOpen}>
          <div>
            <Link to="/underconstruction">
              <MobileButtonColor>Partnerships</MobileButtonColor>
            </Link>
            <StyledA onClick={(e) => handleConnectWallet(e)}>
              <MobileButton>
                {connected ? `Disconnect Wallet ${ethAddress.substring(0, 5)}` : 'Connect Wallet'}
              </MobileButton>
            </StyledA>
          </div>
        </MobileMenu>
      </WrapRight>
    </Wrapper>
  );
};

const WrapInner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const WrapRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
`;
const NavButton = styled.button`
  width: 9.2vw;
  height: 3.5vw;
  color: black;
  background: white;
  border: none;
  font-size: 1.2vw;
  ${media.tablet} {
    font-size: 4.3vw;
  }
`;
const Wrapper = styled.header`
  height: 12vw;
  width: 100vw;
  max-width: 100%;
  padding: 0 6.9vw;
  display: flex;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba(19, 15, 21, 0);
  position: absolute;
  top: 0;
  z-index: 5555;
  opacity: 0;
  ${media.tablet} {
    height: 20.3vw;
    background: rgba(19, 15, 21, 0);
    padding: 0 4.8vw;
    opacity: 1;
    position: fixed;
  }
`;
const TopNavButton = styled.button`
  color: #ffffff;
  background: transparent;
  font-size: 0.75rem;
  text-align: center;
  border: none;
  outline: none;
  text-transform: uppercase;
  width: auto;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  transition: all 250ms linear;
  :hover {
    color: #fefefe;
    transition: all 150ms linear;
  }
  cursor: pointer;
  ${media.tablet} {
    display: none;
  }
`;
const MobileButton = styled(NavButton)`
  display: none;
  ${media.tablet} {
    font-size: 0.8rem;
    text-transform: uppercase;
    display: block;
    width: 90.3vw;
    height: 12vw;
    position: absolute;
    bottom: 24vw;
    left: 4.8vw;
  }
`;
const MobileButtonColor = styled(NavButton)`
  display: none;
  ${media.tablet} {
    font-size: 0.8rem;
    text-transform: uppercase;
    color: ${Colors.cream};
    background-color: ${Colors.black};
    display: block;
    width: 90.3vw;
    height: 12vw;
    position: absolute;
    bottom: 40vw;
    left: 4.8vw;
  }
`;
const MenuButton = styled.a`
  cursor: pointer;
  background-image: url(${hamburger});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 40px;
  height: 40px;
  display: none;
  ${media.tablet} {
    color: white;
    display: block;
  }
`;
const MobileMenu = styled.nav<{ open: boolean }>`
  display: none;
  ${media.tablet} {
    display: ${(props) => (props.open ? 'flex' : 'none')};
    flex-direction: column-reverse;
    align-items: center;
    justify-content: flex-start;
    padding-top: 1.6vw;
    height: calc(35vh);
    width: 100vw;
    position: absolute;
    top: 20.3vw;
    left: 0;
    background: transparent;
  }
`;
const StyledA = styled.a`
  margin-right: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;
const StyledLink = styled(Link)`
  margin-right: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;
const StyledImgA = styled.img`
  height: 45px;
  width: 45px;
  cursor: pointer;
  border: 1px solid #d19459;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  padding: 0.6rem;
  ${media.tablet} {
    height: 35px;
    width: 35px;
  }
`;
const HeaderLeft = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  a {
    cursor: pointer;
  }
`;

export default Header;
