import React from 'react';
import media from '../styles/media';
import styled from 'styled-components';

const Five = () => {
  return (
    <Background>
      <Section>
        <h4>Access</h4>
        <div>IRL Events</div>
        <div>Metaverse Events</div>
        <div>
          Being apart of a global community of artists, explorers, creators, collectors, and
          builders.
        </div>
      </Section>
      <Mobile></Mobile>
      <Section>
        <h4>UTILITY</h4>
        <div>Team and Community focused entirely on creating value for PASS Holders</div>
        <div>Genesis Edition PASS Holders rewarded with legacy editions</div>
        <div>Exclusive drops</div>
        <div>Early access to new projects</div>
        <div>Exclusive offerings (Merch, Brand Partnerships, Activations)</div>
      </Section>
      <Desktop></Desktop>
      <Section>
        <h4>REPRESENTATION</h4>
        <div>
          Supporting the historically underrepresented by providing a platform for creativity,
          promotion and curation.
        </div>
        <div>1 Mint per wallet</div>
        <div>
          Genesis Edition Members are hand selected and given a passcode by someone on the curation
          team in order to be able to mint.
        </div>
      </Section>
      <Mobile></Mobile>
      <Section>
        <h4>ECONOMIC EMPOWERMENT</h4>
        <div>
          Open opportunity for all PASS holders and NFT community members to facilitate brand &
          venue partnerships, with revenue share built into the roadmap.
        </div>
        <div>Transparency across all partnerships, deals and relationships.</div>
      </Section>
    </Background>
  );
};

const Mobile = styled.div`
  display: none;
  width: 85%;
  height: 1px;
  background-color: #bc7a4e;
  margin-top: 0em;
  margin-bottom: 4em;
  ${media.tablet} {
    display: block;
  }
`;
const Desktop = styled.div`
  display: block;
  width: 85%;
  height: 1px;
  background-color: #bc7a4e;
  margin-top: 0em;
  margin-bottom: 4em;
`;
const Background = styled.div`
  overflow: clip;
  width: 100vw;
  max-width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  margin-top: -100px;
`;
const Section = styled.section`
  width: 50%;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0 8rem 4rem 8rem;
  div {
    padding-top: 1rem;
    padding-bottom: 1rem;
    line-height: 2;
    font-family: 'Greycliff', sans-serif;
  }
  h4 {
    text-transform: uppercase;
    color: #bc7a4e;
    font-size: 1.7rem;
    font-weight: 300;
    padding-bottom: 3rem;
  }
  ${media.tablet} {
    width: 100%;
    padding: 0 2rem 4rem 2rem;
  }
`;

export default Five;
