import React from 'react';
import media from '../styles/media';
import styled from 'styled-components';
import crumplebgMobile from '../assets/home/sectionfour.svg';
import crumplebgDesktop from '../assets/home/sectionfour.svg';

const Four = () => {
  return (
    <Background>
      <TextBox>
        <Inner>
          <HeadWrap>
            <h1>What Makes</h1>
          </HeadWrap>
          <BigText>The Party Animalz</BigText>
          <BigTextSans>Speakeasy Society</BigTextSans>
          <SmallText>Different from other NFT projects?</SmallText>
        </Inner>
      </TextBox>
    </Background>
  );
};
const HeadWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30%;
  padding: 20px 0;
  margin-bottom: 40px;
  border-bottom: 2px solid red;
  h1 {
    font-size: 2.5rem;
    font-family: 'Greycliff', sans-serif;
    font-weight: 300;
  }
  ${media.tablet} {
    width: 100%;
    h1 {
      font-size: 1rem;
    }
  }
`;
const BigText = styled.h1`
  text-transform: uppercase;
  font-family: Vintage;
  color: #010101;
  font-size: 3.6em;
  line-height: 1.5;
  ${media.tablet} {
    text-align: center;
    font-size: 2em;
  }
`;
const BigTextSans = styled.h1`
  text-transform: uppercase;
  font-family: 'Greycliff', sans-serif;
  font-weight: 300;
  color: #010101;
  font-size: 3.2em;
  ${media.tablet} {
    text-align: center;
    font-size: 2em;
  }
`;
const SmallText = styled.p`
  font-size: 2rem;
  font-family: 'Greycliff', sans-serif;
  font-weight: 300;
  color: #010101;
  padding-top: 60px;
  ${media.tablet} {
    text-align: center;
    font-size: 1em;
  }
`;
const TextBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3% 0 25% 0;
`;
const Background = styled.div`
  background-image: url(${crumplebgDesktop});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  overflow: clip;
  width: 100vw;
  max-width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${media.tablet} {
    background-image: url(${crumplebgMobile});
    background-position: top;
  }
`;
const Inner = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background: #f2eae1;
  margin: 80px;
  padding: 80px 40px 120px 40px;
  height: 100%;
  width: 75%;
  ${media.tablet} {
    width: 90%;
    margin: 30px;
    padding: 80px 20px 60px 20px;
  }
`;

export default Four;
