import React from 'react';
import media from '../styles/media';
import styled from 'styled-components';

const Three = () => {
  return (
    <Background>
      <BigText>To Do Better Requires Building Differently.</BigText>
    </Background>
  );
};

const BigText = styled.h1`
  font-family: Vintage;
  margin: 200px 20% 200px 20%;
  line-height: 1.7em;
  color: #ffffff;
  font-size: 3.4em;
  ${media.tablet} {
    font-size: 1.5em;
    margin: 100px 20px 100px 20px;
  }
`;

const Background = styled.div`
  overflow: clip;
  width: 100vw;
  max-width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default Three;
