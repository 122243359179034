import React from 'react';
import media from '../styles/media';
import styled from 'styled-components';
import crumplebgMobile from '../assets/home/background-v2.png';
import crumplebgDesktop from '../assets/home/background-v2.png';
import teamEmblem from '../assets/home/team-emblem.svg';

const Seven = () => {
  return (
    <Background>
      <BgImgWrap>
        <img src={teamEmblem} alt="bg" />
      </BgImgWrap>
      <FormInner>
        <BigText>Roadmap 1.0</BigText>
        <TextOuter>
          <TextWrap>
            <H1Outer>
              <H1Inner></H1Inner>
              <h1>LAUNCH</h1>
            </H1Outer>
            <p>Curation team begins PASS code distribution for Genesis Edition</p>
            <p>Mint capability goes live for PASS code recipients</p>
            <p>
              Best GIF and Meme competitions fornon PASS code holders begins (PASS Code rewarded for
              winners)
            </p>
            <p>The First Patron Saints of the party get announced.</p>
          </TextWrap>
          <TextWrap>
            <H1Outer>
              <H1Inner></H1Inner>
              <h1>50% MINTED</h1>
            </H1Outer>
            <p>Announcement of Patron Saints joining the Speakeasy Society.</p>
            <p>First Announcement of Brand partnership.</p>
            <p>Ofﬁcial Branded Membership Cards and party packs gets opened up for orders.</p>
            <p>Ofﬁcial Party Animal Speakeasy to be built in Metvaerse.</p>
          </TextWrap>
          <TextWrap>
            <H1Outer>
              <H1Inner></H1Inner>
              <h1>100% MINTED</h1>
            </H1Outer>
            <p>The Party Animalz Speakeasy Society State of the Union</p>
            <p>First ofﬁcial Metaverse and IRL party “NFT Party Palooza “Date to be announced.</p>
            <p>Party Animals the Movie goes into Production.</p>
            <p>Activation of next Drop with Guest artists.</p>
            <p>Activation of Community art wallet.</p>
            <p>Partnerships team activates.</p>
            <p>Metaverse Build out.</p>
          </TextWrap>
        </TextOuter>
      </FormInner>
    </Background>
  );
};

const TextWrap = styled.div`
  text-align: left;
  margin-top: 5rem;
  h1 {
    text-transform: uppercase;
    color: #f92e06;
    font-size: 1.7rem;
    font-weight: 300;
    margin-left: 2.2rem;
  }
  p {
    color: #010101;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
`;
const TextOuter = styled.div`
  width: 50%;
  padding-left: 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-left: 1px solid red;
  ${media.tablet} {
    width: 80%;
  }
`;
const H1Outer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 3rem;
  margin-left: -4.5rem;
  ${media.tablet} {
    margin-left: -2.2rem;
  }
`;
const H1Inner = styled.div`
  background-color: red;
  border-radius: 50%;
  height: 35px;
  width: 35px;
`;
const BgImgWrap = styled.div`
  z-index: 1;
  width: 40%;
  height: 0%;
  ${media.tablet} {
    width: 80%;
  }
`;
const BigText = styled.h1`
  font-family: Vintage;
  color: #010101;
  font-size: 3.2em;
  line-height: 1.5;
  width: 100%;
  padding-top: 80px;
  padding-bottom: 50px;
  ${media.tablet} {
    padding-top: 50px;
    padding-bottom: 20px;
  }
`;
const Background = styled.div`
  background-image: url(${crumplebgDesktop});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  overflow: clip;
  min-height: 100vh;
  width: 100vw;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${media.tablet} {
    background-image: url(${crumplebgMobile});
    background-position: top;
  }
`;
const FormInner = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: #f2eae1;
  width: 75%;
  margin-top: -80px;
  margin-bottom: 120px;
  padding-top: 100px;
  padding-bottom: 50px;
  ${media.tablet} {
    width: 90%;
  }
`;

export default Seven;
