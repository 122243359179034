export const BASE_URL: string = 'https://partyanimalz.io';
export const N_POINT_CODES: string = 'https://api.npoint.io/9c2779f9188cd5b4b379';

export const PASSWORD_COOKIE_KEY_2: string = 'password_2';
export const REFERRAL_COOKIE_KEY_2: string = 'referral_2';
export const AUTHENTICATED_COOKIE_KEY_2: string = 'authenticated_2';

export const PARTY_ANIMALZ_ETH_ACCOUNT: string = 'pa_eth_account';
export const DEFAULT_NO_REFERRER: string = 'no-referrer';
export const COLLECTION_NAME: string = 'User';
export const COUNTS_COLLECTION_NAME: string = 'Counts';

export const CONTRACT_ADDRESS: string = '0x9c2779f9188cd5b4b379';

export const MINT_PRICE: number = 0.0888;
export const CONTRACT_ADDRESS_MAINNET: string = '0x1Af001BBDA7A314bDd4563453b7b21a016aD590a';
export const CONTRACT_ADDRESS_RINKEBY: string = '0xF95dC8a45E8509965F3a3f341B8743359459C378';
