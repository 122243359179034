import React from 'react';
import Header from './header';
import { ResizeProvider } from '../lib/context/ResizeContext';
import { DAppProvider } from '@usedapp/core';

const Layout: React.FC<{}> = ({ children }) => {
  return (
    <DAppProvider config={{}}>
      <ResizeProvider>
        <Header />
        <main>{children}</main>
      </ResizeProvider>
    </DAppProvider>
  );
};

export default Layout;
