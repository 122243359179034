import React from 'react';
import media from '../styles/media';
import styled from 'styled-components';
import brandHeroLogo from '../assets/home/logo-v2.svg';
import crumplebgMobile from '../assets/home/background-mobile.jpg';
import crumplebgDesktop from '../assets/home/background-desktop2.jpg';

const One = () => {
  return (
    <Background>
      <Inner>
        <InnerImg src={brandHeroLogo} alt="logo" />
      </Inner>
      <FormInner>
        <TextWrap>
          <h1>
            Welcome <br />
            to the party!
          </h1>
          <h4>
            You've been hand selected by our <br /> speakeasy society curation team
          </h4>
          <RefBtn id="mint" onClick={() => {}}>
            I WANT IN
          </RefBtn>
        </TextWrap>
        <RightWrapper>
          <p>
            We are excited to have you as part of the ﬁrst curated NFT project designed for people
            that love to experience life. The Party Animalz Speakeasy Society (PASS) was created to
            provide the ultimate access to the best parties, experiences, partnerships and
            activations in the metaverse and IRL. It’s the last RSVP token you’ll ever need.
          </p>
        </RightWrapper>
      </FormInner>
    </Background>
  );
};

const Background = styled.div`
  background-image: url(${crumplebgDesktop});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  overflow: clip;
  min-height: 100vh;
  width: 100vw;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${media.tablet} {
    background-image: url(${crumplebgMobile});
    background-position: top;
  }
`;
const TextWrap = styled.div`
  width: 60%;
  padding-top: 30px;
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  h1 {
    font-family: Vintage;
    color: #010101;
    font-size: 4.4em;
  }
  h4 {
    font-family: 'Greycliff', sans-serif;
    font-size: 1.2em;
    line-height: 1.5em;
    font-weight: bold;
    color: #010101;
    padding: 2.5em 2.5em 2.5em 0;
  }
  ${media.tablet} {
    width: 100%;
    justify-content: center;
    align-items: center;
    h1 {
      font-size: 2.4em;
      padding-bottom: 1em;
    }
    h4 {
      padding: 0 1em 0 1em;
      margin: 0 0 1em 0;
    }
  }
`;
const RightWrapper = styled.div`
  width: 40%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  p {
    font-family: 'Greycliff', sans-serif;
    font-size: 1em;
    color: #010101;
  }
  ${media.tablet} {
    width: 100%;
    text-align: start;
    p {
      padding: 0 20px 20px 20px;
    }
  }
`;
const InnerImg = styled.img`
  height: 100%;
  width: 100%;
  min-width: 100%;
  ${media.tablet} {
    margin-top: 10%;
    height: 60%;
    width: 60%;
    min-width: 60%;
  }
`;
const Inner = styled.div`
  min-width: 400px;
  width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    margin-bottom: -60px;
  }
  ${media.tablet} {
    img {
      margin-bottom: -20px;
    }
  }
`;
const FormInner = styled.div`
  color: #010101;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background: #f2eae1;
  margin: 20px 80px 80px 80px;
  padding: 60px 140px 65px 140px;
  width: 82%;
  line-height: 2;
  ${media.tablet} {
    flex-direction: column;
    margin: 0;
    padding: 0;
    text-align: center;
  }
`;
const RefBtn = styled.button`
  font-size: 1.2em;
  font-weight: bold;
  color: #101010;
  background: transparent;
  padding: 0.75rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  display: block;
  height: 80px;
  width: 300px;
  border-radius: 50%;
  border: 2px solid #f93f06;
  transition: all 250ms linear;
  :hover {
    color: #f93f06;
    transition: all 150ms linear;
  }
  :active {
    transform: translateY(2px);
    transition: all 150ms linear;
  }
  :visted {
    transform: translateY(2px);
    transition: all 150ms linear;
  }
  ${media.tablet} {
    height: 60px;
    width: 250px;
  }
`;

export default One;
