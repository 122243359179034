import React from 'react';
import media from '../styles/media';
import styled from 'styled-components';
import crumplebgMobile from '../assets/home/background-v2.png';
import crumplebgDesktop from '../assets/home/background-v2.png';
import sectionTwo from '../assets/home/sectiontwo.svg';

const Two = () => {
  return (
    <Background>
      <TextWrap>
        PASS was built because of our collective experience as a team within the NFT & Crypto
        communities. We believe that we must do better as a community to build more inclusive &
        diverse spaces. Parties that are fun, welcoming and where we actually want to be. We knew we
        had to take action and buildthe bridges, relationships and experiences that represent who we
        are as a community.
      </TextWrap>
      <ImgWrap>
        <img src={sectionTwo} />
      </ImgWrap>
    </Background>
  );
};

const TextWrap = styled.div`
  font-family: 'Greycliff', sans-serif;
  font-weight: light;
  width: 50%;
  margin-top: 100px;
  margin-bottom: 100px;
  margin-left: 10%;
  margin-right: 10%;
  padding-right: 20%;
  line-height: 2em;
  ${media.tablet} {
    width: 100%;
    margin-left: 10%;
    margin-right: 10%;
    padding-right: 0%;
  }
`;
const ImgWrap = styled.div`
  position: absolute;
  width: 100%;
  margin-bottom: -10px;
  ${media.tablet} {
    margin-bottom: -5px;
  }
`;
const Background = styled.div`
  background-image: url(${crumplebgDesktop});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  overflow: clip;
  width: 100vw;
  max-width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  ${media.tablet} {
    background-image: url(${crumplebgMobile});
    background-position: top;
  }
`;

export default Two;
