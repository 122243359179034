import React from 'react';
import media from '../styles/media';
import styled from 'styled-components';
import brandHeroLogo from '../assets/home/logo-v2.svg';
import crumplebgMobile from '../assets/home/background-v2.png';
import crumplebgDesktop from '../assets/home/background-v2.png';
import twitter from '../assets/img/i_twitter_white.svg';
import discord from '../assets/img/i_discord_white.svg';

const Seven = () => {
  return (
    <Background>
      <Outer>
        <div>
          <Logo src={brandHeroLogo} alt="brand hero logo" />
        </div>
        <Inner>
          <div>
            <div style={{ paddingBottom: '30px' }}>QUICK LINKS</div>
            <HeaderLeft>
              <a href="https://twitter.com/partyanimalz22" target="_blank">
                <StyledImgA src={twitter} />
              </a>
              <a href="https://discord.gg/3kAMYZJvyP" target="_blank">
                <StyledImgA src={discord} />
              </a>
            </HeaderLeft>
          </div>
          <div>
            <div style={{ paddingBottom: '30px' }}>LEGAL</div>
            <div style={{ paddingBottom: '10px' }}>Privacy</div>
            <div style={{ paddingBottom: '10px' }}>Terms</div>
          </div>
        </Inner>
      </Outer>
      <OuterLow>
        <InnerLow></InnerLow>
        <p>&copy; Copyright 2021 Party Animalz Speakeasy Society All rights reserved</p>
      </OuterLow>
    </Background>
  );
};
const Logo = styled.img`
  width: 350px;
  height: 350px;
  ${media.tablet} {
    width: 180px;
    height: 200px;
  }
`;
const Background = styled.div`
  background-image: url(${crumplebgDesktop});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  overflow: clip;
  width: 100vw;
  min-width: 100vw;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${media.tablet} {
    background-image: url(${crumplebgMobile});
    background-position: top;
  }
`;
const Outer = styled.div`
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5% 0 5%;
`;
const Inner = styled.div`
  width: 25%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  ${media.tablet} {
    width: 100%;
  }
`;
const OuterLow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
  text-align: center;
  ${media.tablet} {
    p {
      font-size: 0.9rem;
      padding: 0 1em 0 1em;
    }
  }
`;
const InnerLow = styled.div`
  width: 80%;
  height: 1px;
  background-color: #ffffff;
  margin-bottom: 60px;
`;
const StyledImgA = styled.img`
  height: 45px;
  width: 45px;
  cursor: pointer;
  border: 1px solid #d19459;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  padding: 0.6rem;
  ${media.tablet} {
    height: 35px;
    width: 35px;
  }
`;
const HeaderLeft = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  a {
    cursor: pointer;
  }
  ${media.tablet} {
    justify-content: flex-start;
  }
`;

export default Seven;
