export default {
  black: '#060707',
  cream: '#F2EAE1',
  red: '#F92E06',
  gold: '#D19459',
  charcoal: '#130f15',
  blue: '#6ae9ef',
  green: '#3ee452',
  yellow: '#7553d4',
  purple: '#9086E2',
};
