import React from 'react';
import media from '../styles/media';
import styled from 'styled-components';
import crumplebgMobile from '../assets/home/background-v2.png';
import crumplebgDesktop from '../assets/home/background-v2.png';
import bgTeam from '../assets/home/backgroundteam.svg';
import ali from '../assets/team/ali.svg';
import gabe from '../assets/team/gabe.svg';
import hovak from '../assets/team/hovak.svg';
import julia from '../assets/team/julia.svg';
import karsen from '../assets/team/karsen.svg';
import keith from '../assets/team/keith.svg';
import knopf from '../assets/team/knopf.svg';
import maryam from '../assets/team/maryam.svg';
import matt from '../assets/team/matt.svg';
import michael from '../assets/team/michael.svg';
import robyn from '../assets/team/robyn.svg';
import rossi from '../assets/team/rossi.svg';
import swan from '../assets/team/swan.svg';

const Six = () => {
  return (
    <Background>
      <BgImgWrap>
        <BgImg src={bgTeam} alt="bg" />
      </BgImgWrap>
      <FormInner>
        <BigText>Meet The Team</BigText>
        <TeamWrapper>
          <Img src={gabe} alt="team" />
          <h2>Gabe Weis</h2>
          <p>Twitter: @GabrielJWeis</p>
        </TeamWrapper>
        <TeamWrapper>
          <Img src={keith} alt="team" />
          <h2>Keith Botha</h2>
          <p>Twitter: @KeithBotha2</p>
        </TeamWrapper>
        <TeamWrapper>
          <Img src={michael} alt="team" />
          <h2>Michael Rutledge</h2>
          <p>Twitter: @RutledgeMj</p>
        </TeamWrapper>
        <TeamWrapper>
          <Img src={robyn} alt="team" />
          <h2>Robyn Hill</h2>
          <p>Twitter: @robyn</p>
        </TeamWrapper>
        <TeamWrapper>
          <Img src={julia} alt="team" />
          <h2>Julia Sky</h2>
          <p>Twitter: @jsky_crypto</p>
        </TeamWrapper>
        <TeamWrapper>
          <Img src={karsen} alt="team" />
          <h2>Karsen Daily</h2>
          <p>Twitter: @karsendaily</p>
        </TeamWrapper>
        <TeamWrapper>
          <Img src={ali} alt="team" />
          <h2>Ali Sabet</h2>
          <p>Twitter: @sabet</p>
        </TeamWrapper>
        <TeamWrapper>
          <Img src={maryam} alt="team" />
          <h2>Maryam Sadik</h2>
          <p>Instagram: @siabrands</p>
        </TeamWrapper>
        <TeamWrapper>
          <Img src={hovak} alt="team" />
          <h2>Z Hovak</h2>
          <p>Twitter: @ZHOVAK</p>
        </TeamWrapper>
        <TeamWrapper>
          <Img src={knopf} alt="team" />
          <h2>John Knopf</h2>
          <p>Twitter: @JohnKnopfPhotos</p>
        </TeamWrapper>
        <TeamWrapper>
          <Img src={swan} alt="team" />
          <h2>Swan VR</h2>
          <p>Twitter: @SwanVR</p>
        </TeamWrapper>
        <TeamWrapper>
          <Img src={rossi} alt="team" />
          <h2>Rossi Meacham</h2>
          <p>Twitter: @metastash</p>
        </TeamWrapper>
        <TeamWrapper>
          <Img src={matt} alt="team" />
          <h2>Matt Willhelm</h2>
          <p>Twitter: @addressXception</p>
        </TeamWrapper>
      </FormInner>
    </Background>
  );
};

const Img = styled.img`
  width: 300px;
  height: 300px;
  margin-top: 0;
  padding: 0;
`;
const BgImgWrap = styled.div`
  width: 100%;
  height: 0%;
`;
const BgImg = styled.img`
  position: absolute;
`;
const BigText = styled.h1`
  font-family: Vintage;
  color: #ffffff;
  font-size: 3.2em;
  line-height: 1.5;
  width: 100%;
  padding-top: 80px;
  padding-bottom: 50px;
  ${media.tablet} {
    font-size: 1.5em;
    padding-top: 50px;
    padding-bottom: 20px;
  }
`;
const TeamWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 33%;
  height: 350px;
  h2 {
    margin-top: -10px;
    margin-bottom: 5px;
    color: #bc7a4e;
    font-family: Greycliff, sans-serif;
    font-size: 1.5em;
    font-weight: 300;
  }
  p {
    font-size: 0.9em;
    color: #ffffff;
  }
  ${media.tablet} {
    width: 100%;
    height: auto;
  }
`;
const Background = styled.div`
  background-image: url(${crumplebgDesktop});
  background-position: center;
  overflow: clip;
  min-height: 100vh;
  width: 100vw;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${media.tablet} {
    background-image: url(${crumplebgMobile});
    background-position: top;
  }
`;
const FormInner = styled.div`
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: #060707;
  width: 75%;
  margin-top: 120px;
  margin-bottom: 120px;
  padding-bottom: 50px;
  ${media.tablet} {
    width: 90%;
  }
`;

export default Six;
