import create from 'zustand';

interface WalletState {
  user: { address: string; email: string; passcode: string };
  updateUser: Function;
  library: object;
  updateLibrary: Function;
}

export const useStore = create<WalletState>((set) => ({
  user: { address: '', email: '', passcode: '' },
  updateUser: (data: any) => set<any>({ user: { ...data } }),
  library: {},
  updateLibrary: (data: any) => set<any>({ library: { ...data } }),
}));
